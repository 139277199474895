
.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.OrderHome-paper-1692 {
    margin-top: 0% !important;
}

.MuiAppBar-colorDefault {
    background-color: white !important;
}